import { ButtonStoryblok, CrosslinkStoryblok, MultilinkStoryblok } from '@/shared/types/storyblok/storyblok-types';
import Button from '../Buttons/Button';
import Container from '../Container/Container';
import styles from './CrosslinkStyle.module.scss';

function getButtonData(link: MultilinkStoryblok, text?: string, color?: string) {
    const buttonData = {
        blok: {
            buttonText: text || '',
            buttonLink: link.story?.full_slug ?? link.cached_url,
            gray: false,
            colorArrow: true,
            darkTheme: true,
            _uid: '',
            component: 'button',
        } as ButtonStoryblok,
        color: color,
    };
    return buttonData;
}

const Crosslink = (data: CrosslinkStoryblok) => {
    const btnData = getButtonData(data.blok.link, data.blok.linkText, data.blok.linkColor);

    return (
        <Container blok={data.blok} className={{ root: styles.root, wrap: styles.container }} code="standard">
            <div className={styles.inner_container} id={data.blok.anchor}>
                <span className={styles.color}>{data.blok.description}</span>
                <div>
                    <Button _uid={''} className={styles.color} component={'button'} darkTheme={true} {...btnData} />
                </div>
            </div>
        </Container>
    );
};

export default Crosslink;
